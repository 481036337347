export enum Entity {
  Id = "entityId",
  Name = "Entity_Name",
  Country = "Entity_Country",
  Website = "Entity_Website",
  TaxId = "Entity_Tax_Id",
  IntegrationTaxId = "Integration_Tax_ID", // one stop shop for the tax id regardless of country; Note: this is calculated not persisted
  IntegrationTaxIdNoFormat = "Integration_Tax_ID_No_Formatting", // Note: this is calculated not persisted
  TaxIndividual = "Tax_Is_Individual",
  NumberEmployees = "Entity_Employee_Count",
  ShortDescription = "Entity_Short_Description",
  Industry = "Entity_Industry",
  Products = "Entity_Products",
  Logo = "Entity_Logo",
  Address = "Global_Address",
  DBAExists = "Tax_DBA_Name_Exists",
  DBA = "Tax_DBA_Names",
  TrustScore = "Entity_Trust_Score",
  PublicId = "Entity_Public_Id",
  Reputation = "Entity_Reputation",
  AttributeOptions = "Entity_Attribute_Options",
  TaxIdOther = "Entity_Tax_Other_ID",
  OtherNames = "Entity_Name_Other",
  Locations = "Locations_List",
  BankAccounts = "Banking_Account_Object",
  BankAccountNum = "Bank_Account_Number",
  Contracts = "GC_Contract_List",
  PaymentAddress = "Payment_Address",
  OrderingAddress = "Ordering_Address",
  FinancialReviewConsentName = "Entity_Financial_Review_Consent_Name",
  FinancialReviewConsentEmail = "Entity_Financial_Review_Consent_Email",
  FinancialReviewConsentPhone = "Entity_Financial_Review_Consent_Phone",
  FinancialReviewConsent = "Entity_Financial_Review_Consent",
  DUNSNumber = "Network_Reference_DUNS",
  OrbisId = "Orbis_ID",
  BVDId = "BVD_ID",
  IdentityScore = "identityScore",
  SalesOrderPayment = "Company Overview/Sales, Order, and Payment",
  TaxInformation = "Company Overview/Tax Information",
}

export enum Connection {
  Id = "connectionId",
  SupplierRequestNewContactName = "Supplier_Request_New_Contact_Name",
  SupplierRequestNewContactEmail = "Supplier_Request_New_Contact_Email",
  SUPPLIER_DISCONNECT_REASON = "Supplier_Disconnect_Reason_Code",
  SupplierPrimaryBusinessOwnerName = "Supplier_Primary_Business_Owner_Name",
  SupplierPrimaryBusinessOwnerEmail = "Supplier_Primary_Business_Owner_Email",
  SupplierSecondaryBusinessOwnerName = "Supplier_Secondary_Business_Owner_Name",
  SupplierSecondaryBusinessOwnerEmail = "Supplier_Secondary_Business_Owner_Email",
  IsRegisteringForCampaigns = "__is_registering_for_campaigns",
  Supplier_Is_Legacy = "Supplier_Is_Legacy",
  BitSight_Data = "BitSight_Data",
  SupplierPrimaryBusinessOwner = "Supplier_Primary_Business_Owner",
}

export enum Address {
  Order = "instanceOrder",
  Line1 = "Address_1",
  Line2 = "Address_2",
  City = "Address_City",
  RegionState = "Address_Region_State",
  Subdivision = "Address_Region_State_Subdivision",
  SubSubdivision = "Address_Region_State_Sub_Subdivision",
  ISOCode = "ISO_Code",
  PostalCode = "Address_Postal_Code",
  Country = "Address_Country",
  CareOf = "Location_CO", // technically not part of the address, but very closely related
  Address = "Address",
  Data = "data",
  Native = "native",
}

export enum LocationsList {
  Address = "Address",
  AddressValid = "Address__Validation_Status",
}

export enum Tax {
  USSSN = "Tax_US_Social_Security_Number",
  USClassification = "Tax_US_Classification",
  USClassification_LLC = "Tax_US_Classification_LLC",
  US_EMPLOYER_ID = "Tax_US_Employer_Id_Number",
  INTEGRATION_TAX_ID = "Integration_Tax_ID",
  US_W9 = "Tax_US_W9",
  INDIA_PAN = "Tax_India_Pan_Number",
  INDIA_PAN_CARD = "Tax_India_PAN_Card_Copy",
  INDIA_CARD = "Tax_India_Card_Copy",
  INDIA_PAN_CHECK = "Tax_India_Pan_Cancelled_Check_Copy",
  MEXICO_ID = "Tax_Mexico_RFC_Number",
  BRAZIL_ID = "Tax_Brazil_CNPJ_Number",
  COLUMBIA_ID = "Tax_Colombia_NIT_Number",
  CHILE_ID = "Tax_Chile_RUT_Number",
  JAPAN_ID = "Tax_Japan_Corporate_Number",
  US_W8 = "Tax_US_Provider_W8BENE",
  GST = "India_GST",
  DISREGARED_TAX_ENTITY = "Tax_US_Disregarded_Entity",
  CIN = "Tax_India_CIN_Certificate",
  LLPIN = "Tax_India_LLPIN_Certificate",
}

export enum GST {
  GST = "India_GST_Certificate",
  State = "India_GST_Address_Region_State_Ref",
}

export enum VAT {
  Austria = "Tax_Austria_VAT_Number",
  Belgium = "Tax_Belgium_VAT_Number",
  Bulgaria = "Tax_Bulgaria_VAT_Number",
  Croatia = "Tax_Croatia_VAT_Number",
  Cyprus = "Tax_Cyprus_VAT_Number",
  Czech = "Tax_Czech_Republic_VAT_Number",
  Denmark = "Tax_Denmark_VAT_Number",
  Estonia = "Tax_Estonia_VAT_Number",
  Finland = "Tax_Finland_VAT_Number",
  France = "Tax_France_VAT_Number",
  Germany = "Tax_Germany_VAT_Number",
  Greece = "Tax_Greece_VAT_Number",
  Hungary = "Tax_Hungary_VAT_Number",
  Ireland = "Tax_Ireland_VAT_Number",
  Italy = "Tax_Italy_VAT_Number",
  Latvia = "Tax_Latvia_VAT_Number",
  Lithuania = "Tax_Lithuania_VAT_Number",
  Luxembourg = "Tax_Luxembourg_VAT_Number",
  Malta = "Tax_Malta_VAT_Number",
  Netherlands = "Tax_Netherlands_VAT_Number",
  Poland = "Tax_Poland_VAT_Number",
  Portugal = "Tax_Portugal_VAT_Number",
  Romania = "Tax_Romania_VAT_Number",
  Sweden = "Tax_Sweden_VAT_Number",
  Slovenia = "Tax_Slovenia_VAT_Number",
  Slovakia = "Tax_Slovakia_VAT_Number",
  Spain = "Tax_Spain_VAT_Number",
  UK = "Tax_UK_VAT_Number",
}

export enum Banking {
  ACHRouting = "Bank_ACH_ABA_Routing",
  BankData = "bank",
  BankDoc = "Bank_Account_Cancel_Check_Upload",
  HolderName = "Bank_Account_Holder_Name",
  AccountNumber = "Bank_Account_Number",
  // logic in the calculation field to get bank account number based on country
  NoFormattingAccountNumber = "Bank_Account_Number_No_Formatting",
  // logic in the calculation field to get bank routing based on country
  BankNumberIntegration = "Bank_Number_Integration",
  BankCountry = "Bank_Country",
}

export enum BankRouting {
  BankRoutingNumber = "Bank_Routing_Number",
  Bank_Sort_Code_Manual = "Bank_Sort_Code_Manual",
}

export enum Contracts {
  Contracts = "GC_Contract_Document",
}

export enum Directory {
  TITLE = "Directory_Title",
  NAME = "Directory_Name",
  EMAIL = "Directory_Email",
  PHONE = "Directory_Phone",
  NOTES = "Directory_Notes",
}

export enum Tin {
  TIN_RESULT = "__Tin_Result",
  OFAC = "__OFAC",
}

// Internal Use Answer Keys
export enum Internal {
  PREFERRED = "Preferred_Supplier",
  PREFERRED_SUPPLIER = "Supplier_Is_Preferred",
  NETWORK_SUPPLIER = "Business_Engagement_Network_Supplier",
  GRAPHITE_NETWORK_SUPPLIER = "Supplier_Is_Graphite_Network",
  DIVERSE_SUPPLIER = "Diversity_Confirmation",
  DIVERSE_SUPPLIER_CERTIFIED = "Diversity_Company_Certificate",
  NON_GRAPHITE_NETWORK = "nonGraphiteNetwork",
  OVERALL_RISK = "__Overall_Risk",
  VERIFY_DATE = "__Verify_Date",
  VERIFIED_BY = "__Verified_By",
  COMPANY_UPDATE_DATE = "__Company_Update_Date",
  PROFILE_UPDATE_DATE = "__Profile_Update_Date",
  VALIDATION_STATUS = "__Validation_Status",
  VALIDATION_NOTE = "__Validation_Note",
  VALIDATED_BY = "__Validated_By",
  VALIDATED_DATE = "__Validated_Date",
  VALIDATION_SUPPORTING_FILES = "__Validation_Supporting_Files",
  VALIDATION_FILE_NOTE = "__Validation_File_Note",
  FORCED_REQUIRED_INSTANCE = "__Forced_Required_Instance",
  NEW_INSTANCE = "__New_Instance",
  LAST_CLOSED_REVIEW = "__Last_Closed",
  DELETED = "__Deleted",
  ONE_TIME_PULL_SECZETTA = "Ready_For_One_Time_Pull_SecZetta",
  UPDATED_DATE = "__Updated_Date",
  RAPID_RATING_POLL_DATE = "__Rapid_Ratings_Data_Poll_Date",
  SCORE_OVERRIDE = "__Score_Override",
  CONNECTION_BADGE = "__connection_badge",
  BADGE_FILE = "__badge_file",
  CONNECTION_CAMPAIGN = "__connection_opportunity",
  DISCONNECT_REASON_REGISTERED = "Registered",
  CUSTOMIZED_CONNECTION_ROLE = "Graphite_Connection_Type",
  ENTITY_EMAIL_LOGO = "__Entity_Email_Logo",
  ENTITY_EMAIL_LOGO_COMPANY = "__Entity_Email_Logo_Company",
  TRACKING = "__tracking",
  HYDRATED_ANSWER_KEY = "__hydrated",
  GIACT_CONNECTION_GROUP = "__GIACT_Connection_Group",
  GIACT_REF_ID = "__GIACT_Item_Reference_ID",
  GIACT_REF_CHECK_TYPE = "__GIACT_Check_Type", // see GIACTCheckType enum for value
  GIACT_VERIFICATION_RESULT = "__GIACT_Verification_Result", // see VerificationResult enum for value
  KIT_UPDATED_AT = "updatedAt",
  KIT_UPDATED_BY = "updatedBy",
  KIT_CREATED_AT = "createdAt",
  KIT_CREATED_BY = "createdBy",
  KIT_UPDATED_BY_ENTITY = "updatedByEntity",
  ANSWER_AUTHORITY = "__Answer_Authority",
  UNVERIFIED = "__Unverified",
  GIACT_DATA = "__Giact_Data",
  ENTITY_ASSOCIATES = "__Entity_Associates",
}

export const ONE_TIME_PULL_SECZETTA_VALUE = "seczetta";

export enum CountryCode {
  ALGERIA = "DZ",
  AUSTRALIA = "AU",
  AUSTRIA = "AT",
  AZERBAIJAN = "AZ",
  BAHRAIN = "BH",
  BELGIUM = "BE",
  BRAZIL = "BR",
  BULGARIA = "BG",
  CANADA = "CA",
  CHILE = "CL",
  CHINA = "CN",
  COLUMBIA = "CO",
  COSTA_RICA = "CR",
  CROATIA = "HR",
  CYPRUS = "CY",
  CZECH = "CZ",
  DENMARK = "DK",
  EGYPT = "EG",
  ESTONIA = "EE",
  FINLAND = "FI",
  FRANCE = "FR",
  GERMANY = "DE",
  GREECE = "GR",
  HONG_KONG = "HK",
  HUNGARY = "HU",
  INDIA = "IN",
  IRAQ = "IQ",
  IRAN = "IR",
  IRELAND = "IE",
  ITALY = "IT",
  ISRAEL = "IL",
  JAPAN = "JP",
  JORDAN = "JO",
  KAZAKHSTAN = "KZ",
  KOSOVO = "XK",
  KUWAIT = "KW",
  LATVIA = "LV",
  LEBANON = "LB",
  LITHUANIA = "LT",
  LUXEMBOURG = "LU",
  MALTA = "MT",
  MEXICO = "MX",
  MONACO = "MC",
  MOROCCO = "MA",
  NETHERLANDS = "NL",
  NORWAY = "NO",
  OMAN = "OM",
  PANAMA = "PA",
  POLAND = "PL",
  PORTUGAL = "PT",
  QATAR = "QA",
  ROMANIA = "RO",
  RUSSIA = "RU",
  SAUDI_ARABIA = "SA",
  SERBIA = "RS",
  SINGAPORE = "SG",
  SLOVAKIA = "SK",
  SLOVENIA = "SI",
  SPAIN = "ES",
  SWEDEN = "SE",
  SWITZERLAND = "CH",
  TAIWAN = "TW",
  THAILAND = "TH",
  UKRAINE = "UA",
  UNITED_ARAB_EMIRATES = "AE",
  UK = "GB",
  US = "US",
  VATICAN_CITY = "VA",
  YEMEN = "YE",
}

export const VatKeyToCountryCode: {[key in VAT]: CountryCode} = {
  [VAT.Austria]: CountryCode.AUSTRIA,
  [VAT.Belgium]: CountryCode.BELGIUM,
  [VAT.Bulgaria]: CountryCode.BULGARIA,
  [VAT.Croatia]: CountryCode.CROATIA,
  [VAT.Cyprus]: CountryCode.CYPRUS,
  [VAT.Czech]: CountryCode.CZECH,
  [VAT.Denmark]: CountryCode.DENMARK,
  [VAT.Estonia]: CountryCode.ESTONIA,
  [VAT.Germany]: CountryCode.GERMANY,
  [VAT.Greece]: CountryCode.GREECE,
  [VAT.Finland]: CountryCode.FINLAND,
  [VAT.France]: CountryCode.FRANCE,
  [VAT.Hungary]: CountryCode.HUNGARY,
  [VAT.Ireland]: CountryCode.IRELAND,
  [VAT.Italy]: CountryCode.ITALY,
  [VAT.Lithuania]: CountryCode.LITHUANIA,
  [VAT.Luxembourg]: CountryCode.LUXEMBOURG,
  [VAT.Latvia]: CountryCode.LATVIA,
  [VAT.Malta]: CountryCode.MALTA,
  [VAT.Netherlands]: CountryCode.NETHERLANDS,
  [VAT.Poland]: CountryCode.POLAND,
  [VAT.Portugal]: CountryCode.PORTUGAL,
  [VAT.Romania]: CountryCode.ROMANIA,
  [VAT.Spain]: CountryCode.SPAIN,
  [VAT.Sweden]: CountryCode.SWEDEN,
  [VAT.Slovakia]: CountryCode.SLOVAKIA,
  [VAT.Slovenia]: CountryCode.SLOVENIA,
  [VAT.UK]: CountryCode.UK,
};

export const CountryCodeToVatKey: Partial<{[key in CountryCode]: VAT}> = {
  [CountryCode.AUSTRIA]: VAT.Austria,
  [CountryCode.BELGIUM]: VAT.Belgium,
  [CountryCode.BULGARIA]: VAT.Bulgaria,
  [CountryCode.CROATIA]: VAT.Croatia,
  [CountryCode.CYPRUS]: VAT.Cyprus,
  [CountryCode.CZECH]: VAT.Czech,
  [CountryCode.DENMARK]: VAT.Denmark,
  [CountryCode.ESTONIA]: VAT.Estonia,
  [CountryCode.GERMANY]: VAT.Germany,
  [CountryCode.GREECE]: VAT.Greece,
  [CountryCode.FINLAND]: VAT.Finland,
  [CountryCode.FRANCE]: VAT.France,
  [CountryCode.HUNGARY]: VAT.Hungary,
  [CountryCode.IRELAND]: VAT.Ireland,
  [CountryCode.ITALY]: VAT.Italy,
  [CountryCode.LITHUANIA]: VAT.Lithuania,
  [CountryCode.LUXEMBOURG]: VAT.Luxembourg,
  [CountryCode.LATVIA]: VAT.Latvia,
  [CountryCode.MALTA]: VAT.Malta,
  [CountryCode.NETHERLANDS]: VAT.Netherlands,
  [CountryCode.POLAND]: VAT.Poland,
  [CountryCode.PORTUGAL]: VAT.Portugal,
  [CountryCode.ROMANIA]: VAT.Romania,
  [CountryCode.SPAIN]: VAT.Spain,
  [CountryCode.SWEDEN]: VAT.Sweden,
  [CountryCode.SLOVAKIA]: VAT.Slovakia,
  [CountryCode.SLOVENIA]: VAT.Slovenia,
  [CountryCode.UK]: VAT.UK,
};

// These do not have their country code as their VAT prefix.
export enum VATPrefixExceptions {
  GREECE = "EL",
  NORTHERN_IRELAND = "XI",
}

export enum Subsidiaries {
  PARENT_GRAPHITE_ID = "Entity_Parent_Company_Graphite_ID",
  PARENT_NAME = "Entity_Parent_Company_Name",
  SUBSIDIARY_LIST = "Subsidiary_List",
  SUBSIDIARY_NAME = "Subsidiary_Name",
  SUBSIDIARY_GRAPHITE_ID = "Subsidiary_Graphite_ID",
}

export enum TopicReviewAnswerKeys {
  OWNER = "Topic_Review_Owner",
  DOCUMENT = "Topic_Review_Document",
  RISK_LEVEL = "Topic_Review_Risk_Level",
  APPROVAL = "Topic_Review_Approval",
  APPROVAL_PRODUCTS = "Topic_Review_Approval_Product",
  NEXT_REVIEW = "Topic_Review_Next_Review_Date",
  AUTO_APPROVED = "Review_Auto_Approved",
  BUSINESS_APROVAL = "Topic_Review_Document_Business_Approval",
  APPROVER = "Topic_Review_Document_Business_Approval_Owner",
  NOTES = "Topic_Review_Notes",
  SUBSIDIARY = "Topic_Review_Approval_Subsidiary",
  SUBSIDIARIES = "Topic_Review_Approval_Subsidiary_List",
  REVIEW_START_DATE = "__Review_Start_Date",
  REVIEW_END_DATE = "__Review_End_Date",
  INHERITED = "__Inherited",
  NON_INHERITED_PARENT_REVIEW = "__Non_Inherited_Parent_Review",
  REQUESTED_BY = "Topic_Review_Requested_By",
  REQUESTED_AT = "Topic_Review_Requested_Date",
  DELETION_NOTE = "Topic_Review_Deletion_Note",
}

export enum IntegrationAnswerKeys {
  RECORD = "ERP_Record",
  STATUS = "Integration_ERP_Status",
  DATE = "Integration_ERP_Date",
  NOTES = "Integration_ERP_Notes",
  NAME = "Integration_ERP_Name",
  ERP_ID = "ERP_ID",
  READY_TO_TRANSACT = "ERP_Ready_To_Transaction",
  ERP_ENABLED = "Integration_ERP_ON",
  PRIMARY_BANK_KEY = "$PRIMARY_BANK_KEY",
  BANK_LIST_KEY = "ERP_Bank_List",
  BANK_LIST_PRIMARY_KEY = "ERP_Bank_List_Primary",
  CONTACT_ERP_ID = "Payment_Contact_ERP_Key",
  CONTACTS = "ERP_Contact_ID",
  BANK_ERP_KEY = "Bank_ERP_Key",
  LOCATION_ERP_KEY = "Location_ERP_Key",
  SAGE_BANK_LIST = "Sage_Bank_List",
  NETSUITE_BANK_LIST = "Netsuite_Bank_List",
  NETSUITE_BANK_LIST_PRIMARY = "Netsuite_Bank_List_Primary",
  // OKTA_SUBSIDIARY_BANKS = "OKTA_Subsidiary",
}

export enum OrderingAnswerKeys {
  ARIBA_NETWORK_ID = "Ordering_Information_Method_Contact_Network_Ariba",
}

export enum CampaignAnswerKeys {
  CAMPAIGN_LIST = "gc_Opportunity_List",
  CAMPAIGN_ID = "__campaign_id",
  CAMPAIGN_SLUG = "__campaign_slug",
  CAMPAIGN_NAME = "__campaign_name",
}

export enum RiskLevels {
  HIGH = "High",
  MEDIUM = "Medium",
  LOW = "Low",
  PENDING = "Pending",
  UNKNOWN = "Unknown",
  CRITICAL = "Critical",
  REVIEW = "review",
}

export enum OfacLevels {
  PASS = "passed",
  FAIL = "failed",
  REVIEW = "review",
  PENDING = "pending",
}

export const OfacToRisk = {
  [OfacLevels.PASS]: RiskLevels.LOW,
  [OfacLevels.FAIL]: RiskLevels.HIGH,
  [OfacLevels.REVIEW]: RiskLevels.REVIEW,
  [OfacLevels.PENDING]: RiskLevels.UNKNOWN,
};

export const RiskLevelNumbers = {
  [RiskLevels.UNKNOWN]: 0,
  [RiskLevels.PENDING]: 1,
  [RiskLevels.LOW]: 2,
  [RiskLevels.MEDIUM]: 3,
  [RiskLevels.HIGH]: 4,
  [RiskLevels.CRITICAL]: 5,
};

export enum MagicAnswerKeys {
  FLAGGED_QUESTION = "Flagged question",
  TOPIC_RISK = "$TopicRisk",
  TOPIC_OWNER = "$TopicOwner",
  LINK_TO_TOPIC = "$LinkToTopic",
  COUNTERPARTY_TOPIC_OWNER = "Counterparty.$TopicOwner",
  CONNECTION_STAGE = "$ConnectionStage",
  CONNECTION_DETAILS = "$ConnectionDetails",
  CURRENT_USER = "$CurrentUser",
  CURRENT_USER_NAME = "$CurrentUserName",
  CURRENT_USER_EMAIL = "$CurrentUserEmail",
  CURRENT_USER_ROLES = "$CurrentUserRoles",
  CURRENT_USER_CONNECTION_ROLE = "$CurrentUserConnectionRole",
  FIRST = "$First", // Set to "y" on the first instance of every multiple group
  GROUP_KEY = "$GroupKey",
  REQUESTER = "$Requester",
  REQUESTER_USER_NAME = "$RequesterUserName",
  REQUESTER_USER_EMAIL = "$RequesterUserEmail",
  DELETED_INSTANCE = "$Deleted",
  SUBMIT_INSTANCE_ID = "$SubmitInstanceID",
  INSTANCE_ID = "$InstanceID",
  API_ERRORS = "$APIErrors",
  PUBLIC_ID = "$PublicId",
  INTERFACE_TYPE = "$InterfaceType",
  INVITEE_NAME = "$InviteeName",
  INVITEE_EMAIL = "$InviteeEmail",
  ASSIGNEES = "$Assignees",
  TASK_NAME = "$TaskName",
  LINK_TO_TASK = "$LinkToTask",
  TASK_STATUS = "$TaskStatus",
  ADMIN_EMAIL_DOMAINS = "$AdminEmailDomains",
  APPROVAL_REQUESTER = "$ApprovalRequester",
  APPROVAL_REQUESTER_NAME = "$ApprovalRequesterName",
  APPROVAL_REQUESTER_EMAIL = "$ApprovalRequesterEmail",
  TASK_REQUESTER = "$TaskRequester",
  TASK_REQUESTER_NAME = "$TaskRequesterName",
  TASK_REQUESTER_EMAIL = "$TaskRequesterEmail",
  TASK_COMPLETED_BY = "$TaskCompletedBy",
  TASK_COMPLETED_BY_NAME = "$TaskCompletedByName",
  TASK_COMPLETED_BY_EMAIL = "$TaskCompletedByEmail",
  ANSWER_CHANGED_BY = "$AnswerChangedBy",
  ANSWER_CHANGED_BY_NAME = "$AnswerChangedByName",
  ANSWER_CHANGED_BY_EMAIL = "$AnswerChangedByEmail",
  ELEMENT_NAME = "$ElementName",
  TOPIC_NAME = "$TopicName",
  TOPIC_ID = "$TopicID",
  WORKFLOW_TASK_DATA = "$WorkflowTaskData",
  MINE = "$Mine",
  MASTER = "$Master",
  CHANGED_INSTANCES = "$ChangedInstances",
  DELETED_INSTANCES = "$DeletedInstances",
  AFFECTED_USER = "$AffectedUser",
  AFFECTED_USER_NAME = "$AffectedUserName",
  AFFECTED_USER_EMAIL = "$AffectedUserEmail",
  AFFECTED_USER_STATUS = "$AffectedUserStatus",
  AFFECTED_USER_SEND_INVITE = "$AffectedUserSendInvite",
  IMMEDIATE_MANUAL_REVIEW = "$ImmediateManualReview",
  APPROVED = "$Approved",
  APPROVAL_NOTES = "$ApprovalNotes",
  CAMPAIGN = "$Campaign",
  CAMPAIGNS = "$Campaigns",
  CAMPAIGN_URL = "$CampaignUrl",
  CAMPAIGN_SLUG = "$CampaignSlug",
  CAMPAIGN_OWNER = "$CampaignOwner",
  CAMPAIGN_OWNER_EMAIL = "$CampaignOwnerEmail",
  CAMPAIGN_OWNER_NAME = "$CampaignOwnerName",
  CURRENT_CAMPAIGN_ID = "$CurrentCampaignId",
  REQUESTING_ROLE = "$RequestingRole",
  VALUE = "$Value",
  INTEGRATION_ANSWER_KEYS = "__IntegrationAnswerKeys",
  ROLE_USER_EMAILS = "$RoleUserEmails",
  IS_REQUESTING_PROFILE = "$IsRequestingProfile",
  MATCHES = "$Matches",
  SECZETTA = "$SecZetta",
  INTEGRATION = "$Integration",
  HAS_BADGE = "$HasBadge",
  IS_BULK_UPDATE = "$IsBulkUpdate",
  RECIPIENTS = "$Recipients",
  RECIPIENT_NAMES = "$RecipientNames",
  RECIPIENT_EMAILS = "$RecipientEmails",
  LINK_TO_KIT = "$LinkToKit",
  NETWORK_INVITATION = "$NetworkInvitation",
  LINK_TO_USER_INVITED = "$LinkToUserInvited",
  LINK_TO_USER_ACCESS_REQUESTED = "$LinkToUserAccessRequested",
  SUBJECT_PREFIX = "$SubjectPrefix",
  APPROVAL_STATUS = "$ApprovalStatus",
  LINK_TO_INVITE_DIALOG = "$LinkToInviteDialog",
  ONE_TIME_PULL = "Ready_For_One_Time_Pull", // not having the $ prefix is intentional due to the magic read/write nature of this answer key
  OPEN_TASK_NAMES = "$OpenTaskNames",
  KIT_ID = "$KitId",
  ENVIRONMENT = "$Environment",
  HAS_OPEN_ACTION_PLAN = "$HasOpenActionPlan",
  SSOAttributes = "$SSOAttributes",
  ACTIVE_PROFILE = "$ActiveProfile",
  FEATURES = "$Features",
  LINK_TO_CONNECTION = "$LinkToConnection",
  COMPANY_CREATE = "$CompanyCreate",
  CHANGED_ANSWER_KEYS = "$ChangedAnswerKeys",
  WEBHOOK_RESPONSE = "$WebhookResponse",
  PREVIOUS_WEBHOOK_RESPONSE = "$PreviousWebhookResponse",
  SUPPLIER_CONTACT_NAME = "Supplier_Contact_Name", // not having the $ prefix is intentional due to the magic read/write nature of this answer key
  SUPPLIER_CONTACT_EMAIL = "Supplier_Contact_Email", // not having the $ prefix is intentional due to the magic read/write nature of this answer key
  FAILED_VALIDATION_INSTRUCTIONS = "$FailedValidationInstructions",
  VALIDATION_ANSWER_KEY = "$ValidationAnswerKey",
  VALIDATION_INSTANCE_ID = "$ValidationInstanceId",
  SECRETS = "$Secrets",
  CONNECTION = "$Connection",
  KITS = "$Kits",
  FOR_EACH = "$ForEach",
  COMPANY_HAS_ONE_PROVE_VERIFIED_USER = "$CompanyHasOneProveVerifiedUser",
  COMPANY_HAS_ONE_VERIFF_VERIFIED_USER = "$CompanyHasOneVeriffVerifiedUser",
  ASSESSMENT_GROUPS = "$AssessmentGroups",
  SOURCE_INSTANCE_ID = "$SourceInstanceId",
  ORIGINAL_ASSIGNEES = "$OriginalAssignees",
  ITERATOR = "$Iterator",
  OPEN_TOPIC_REVIEWS = "$OpenTopicReviews",
  TASK_TYPE = "$TaskType",
  VERIFICATION_OVERRIDE = "$VerificationOverride",
  MASTER_DATA_TYPE = "$MasterDataType",
  ACCEPT_USERS = "$AcceptUsers",
  CONNECTION_HISTORY = "$ConnectionHistory",
  CONNECTION_HISTORY_TITLE = "$ConnectionHistoryTitle",
  CONNECTION_HISTORY_DATE = "$ConnectionHistoryDate",
  CONNECTION_HISTORY_STAGE = "$ConnectionHistoryStage",
  THIRD_PARTY_DETAILS = "$ThirdPartyDetails",
  TOPIC_OWNER_ROLE = "$TopicOwnerRole",
}

export enum KitParentField {
  Connection_Kit_Parent_Id = "Connection_Kit_Parent_Id",
  Kit_Parent_Id = "Kit_Parent_Id",
  Connection_Kit_Parent_Type = "Connection_Kit_Parent_Type",
  Kit_Parent_Type = "Kit_Parent_Type",
}

export enum CustomESFields {
  Name = "displayName",
  SearchBlob = "__search_blob",
  ConnectionStatus = "status",
  CreatedBy = "createdBy",
  PublicId = "publicId",
  DisplayBadges = "displayBadges",
  ConnectedEntityIds = "__connected_entity_ids",
  InviteeName = "inviteeName",
  InviteeEmail = "inviteeEmail",
  FirstTaskReadDate = "firstTaskReadDate",
  OpenTaskAssigneeIds = "openTaskAssigneeIds",
  CounterPartyOpenTaskAssigneeIds = "counterPartyOpenTaskAssigneeIds",
  SignUpRoleIds = "signUpRoleIds",
  // count includes open tasks from both companies on the connection
  TotalConnectionOpenTaskCount = "totalConnectionOpenTaskCount",
  WaitingOnTaskStatus = "waitingOnTaskStatus",
}

export const CampaignESField = `${CampaignAnswerKeys.CAMPAIGN_LIST}.${CampaignAnswerKeys.CAMPAIGN_ID}`;

export const DisplayBadgesESField = `${CustomESFields.DisplayBadges}._id`;

export const AllowThemToDisplayMyBadge = "Badge_Access_On_My_Profile";

export const AllowThemToViewMyBadge = "Badge_Visibility_On_Other_Profile";

export enum BooleanAnswers {
  YES = "y",
  NO = "n",
}

export enum SaveType {
  Save = "save",
  Submit = "submit",
  SaveAndCompleteTopic = "save and complete topic",
  CompleteReview = "complete review", // Wishful thinking
}

export enum TranslationSource {
  USER = "user",
  MACHINE = "machine",
  PROFESSIONAL = "professional",
}

export enum FreeTextAnswerKeys {
  ANSWER_LOCALE = "__answer_locale",
  VALUE = "value",
  DATE = "date",
  FROM_LOCALE = "from_locale",
  TRANSLATION_SOURCE = "translation_source",
  TRANSLATOR = "translator",
}

export enum ThumbDirection {
  UP = "up",
  DOWN = "down",
}

export enum SpecialTabs {
  OVERVIEW = "Overview",
}

export enum SpecialTopics {
  OVERVIEW = "Overview/Overview",
}

export enum ThirdPartyData {
  DarkbeamData = "Darkbeam_Data",
  RapidRatingsData = "Rapid_Ratings_Data",
  RapidRatingsOutreach = "outreach",
  DowJonesData = "DowJones_Data",
  CyberPassportData = "CyberPassport_Data",
  SecZettaData = "SecZetta_Data",
  RiskReconData = "RiskRecon_Data",
  DitchCarbonData = "DitchCarbon_Data",
  DunBradstreetData = "DunBradstreet_Data",
  BvDData = "BvD_Data",
  EcoVadisData = "EcoVadis_Data",
  SmartCubeData = "SmartCube_Data",
  BitSightData = "BitSight_Data",
}

export const noHistoryAnswerKeys = [ThirdPartyData.RapidRatingsData];

export enum DocuSignStatus {
  DRAFT = "draft",
  SENT = "sent",
  DELIVERED = "delivered",
  COMPLETED = "completed",
  DECLINED = "declined",
  VOIDED = "voided",
}

export enum RapidRatings {
  RelationshipOwnerName = "Rapid_Ratings_Relationship_Owner_Name",
  RelationshipOwnerEmail = "Rapid_Ratings_Relationship_Owner_Email",
}

export enum RBAAnswerKeys {
  VERSION = "Supplier_Deliverables_Deliverable_Version",
  LAST_UPDATED = "Supplier_Deliverables_Deliverable_Last_Updated",
  EFFECTIVE_FROM = "Supplier_Deliverables_Deliverable_Effective_From",
  EFFECTIVE_TO = "Supplier_Deliverables_Deliverable_Effective_To",
  ASSESSMENT_ID = "Supplier_Deliverables_Deliverable_Assessment_ID",
  ERROR_MESSAGE = "Supplier_Deliverables_Deliverable_Error_Message",
  FACILITY_RBA_ID = "Supplier_Facility_RBA_ID",
  PREFIX_FOR_QUESTION = "_Supplier_RBA_",
  PREFIX_FOR_SECTION = "_Supplier_RBA_Score_",
  Loc_RBA_Company_ID = "Loc_RBA_Company_ID",
  Loc_RBA_Facility_ID = "Loc_RBA_Facility_ID",
}

export enum RBAAuditAnswerKeys {
  PREFIX_FOR_QUESTION = "_Audit_RBA_",
  SUFFIX_FOR_AUDIT_SUPPLIER = "_Audit_Supplier",
}
export enum RedirectAnswerKeys {
  REDIRECT_LIST = "Redirect_List",
  REDIRECT_ENTITY_NAME = "Redirect_Entity_Name",
  REDIRECT_ENTITY_ID = "Redirect_Entity_Id",
  REDIRECT_DATE = "Redirect_Date",
  REDIRECT_USER_NAME = "Redirect_User_Name",
  REDIRECT_USER_Email = "Redirect_User_Email",
}

export const EcoVadisGlobalId = "EcoVadis_Data_ID";

export const SmartCubeGlobalId = "SmartCube_Data_ID";

export const NotFoundLabel = "Not Found";

// Let's hardcode for now and add to hammer in future if we get more of these.
export const CompaniesThatActAsSellersNotBuyers: string[] = [
  "US16232", // CyberPassport
  "US16300", // Atlanta Black Chambers
];

export const NSJTopicIds = ["Connection_NewRequest", "Connection_NewRequest_Customer"];

export const ALL_OPTION_VALUE = "__ALL__";

export const KIT_INTERFACE_CHANGE_DATES = "__KIT_INTERFACE_CHANGE_DATES";
export const ANSWER_METADATA_SUFFIX = "__meta";
