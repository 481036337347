import {translate, userLocalizeLabel} from "@/composables/i18n";
import {getTranslatedOptionsByKey} from "@/composables/lists";
import type {UserInputOptions, UserOption, UserOptionsGroup} from "@/composables/questions/types";
import type {Answers, ConnectionDataLite, ConnectionOrKitContext} from "pg-isomorphic/api/connection";
import type {ListAnswerPathConfig, ListOption} from "pg-isomorphic/api/lists";
import type {AdvancedSearchField} from "pg-isomorphic/api/search";
import type {GetUserOptions, ListUser} from "pg-isomorphic/api/user";
import {ConnectionRole, Locale, QuestionType, RoleTypeName} from "pg-isomorphic/enums";
import {EntityAccessStatus, SELECT_DEFAULT_NUM_USERS} from "pg-isomorphic/enums/users";
import {getOptionsFromListByAnswerPath, listItemsFind} from "pg-isomorphic/lists";
import type {QuestionOption, ReturnedOptionsProcessingMethod} from "pg-isomorphic/options";
import {sortOptions} from "pg-isomorphic/options";
import {
  filterOptions,
  getOptionsData,
  GetOptionsMethod,
  getOptionsProcessingMethod,
  getOptionsWithoutServerCall,
  mergeOptions,
} from "pg-isomorphic/options";
import {findTopic} from "pg-isomorphic/profile";
import type {JSONQuestion} from "pg-isomorphic/utils";
import {coerceToArray} from "pg-isomorphic/utils";
import {clone, find as baseFind, pathOr, uniqBy} from "ramda";
import globalLogger from "../../logging";
import {getUsers} from "@/composables/user";
import type {ContactOptionsByEntityStorageKey} from "@/composables/local";
import {getFromStorage, saveInStorage, StorageKey} from "@/composables/local";
import type {ContactAnswer} from "pg-isomorphic/api/answers";
import isEmpty from "lodash/isEmpty";
import {NUM_OF_CONTACTS_TO_SHOW} from "pg-isomorphic/enums/contacts";
import {getUserActiveEntityId} from "@/composables/vuex";

const logger = globalLogger.getLogger("getOptions-ts");

export interface ListOptionsOptions {
  forSearch?: boolean;
  // Return just an option with a value matching this *value*. This is for looking up a label from its value (e.g. <ListLookup />).
  // It may find wrong label if duplicate values exist. Only falls back if it can't find one the "proper" way.
  valueSearchFallback?: string;
}

export async function getOptionsAsync(
  elementData: JSONQuestion,
  connectionData?: ConnectionDataLite,
  userLocale?: string,
  optionsProcessingMethodExternal?: ReturnedOptionsProcessingMethod,
  segmentationContext?: ConnectionOrKitContext,
  defaultValue?: string[], // only for users because we limit
): Promise<QuestionOption[]> {
  const {answers, questions, answerKey} = getOptionsData(elementData.optionsAnswerKey, connectionData);
  const optionsProcessingMethod = optionsProcessingMethodExternal || getOptionsProcessingMethod(elementData, answers);

  if (optionsProcessingMethod.getOptionsMethod === GetOptionsMethod.GET_OPTIONS_FROM_SERVER) {
    if (
      elementData.type === QuestionType.USER_SELECT_EMAIL ||
      elementData.type === QuestionType.USER_SELECT ||
      elementData.type === QuestionType.CONTACT
    ) {
      const options = await getUserInputOptions({
        questionData: elementData,
        connectionOrKitContext: segmentationContext,
        answers: connectionData.answers,
        defaultUserIds: defaultValue,
      });
      return filterOptions(options, elementData.optionsFilter, answers);
    } else {
      const filteredOptions = filterOptions(
        await getListOptions(
          elementData.list.key,
          userLocale,
          elementData.list.owner ?? elementData.owner,
          elementData.list.answerPath,
          answers,
          {
            // this means this is actually a faux question built from `AdvancedSearchField`
            forSearch: !!(elementData as unknown as AdvancedSearchField).forSearch,
          },
        ),
        elementData.optionsFilter,
        answers,
      );

      // when getting data for subdivisions, options is not an array and this is not necessary
      // NOTE: This is where `helpText` will end up on the options!
      //       (and the only way when using `list.key` at the time of this writing)
      if (Array.isArray(filteredOptions) && Array.isArray(elementData.options)) {
        return mergeOptions(filteredOptions, elementData.options || []);
      }

      return sortOptions(filteredOptions, elementData.key);
    }
  } else {
    return getOptionsWithoutServerCall(elementData, optionsProcessingMethod, answers, questions, answerKey);
  }
}

export async function getListOptions(
  key: string,
  locale: string = Locale.EN_US,
  owner?: string,
  // `answerPath` is only needed for nested/hierarchical lists, and it needs `answers` to function
  answerPath: ListAnswerPathConfig[] = [],
  answers: Answers = {},
  listOptionsOptions: ListOptionsOptions = {},
): Promise<ListOption[]> {
  const listItems = await getTranslatedOptionsByKey(key, owner, locale);

  const options = getOptionsFromListByAnswerPath(listItems, answers, answerPath, listOptionsOptions.forSearch);

  if (
    listOptionsOptions.valueSearchFallback &&
    !options.find((opt) => opt.value === listOptionsOptions.valueSearchFallback)
  ) {
    const foundItem = listItemsFind(listItems, (item) => item.value === listOptionsOptions.valueSearchFallback);

    if (foundItem) {
      return [
        {
          label: foundItem.label,
          value: foundItem.value,
        },
      ];
    }
  }

  return sortOptions(options, key).map((opt) => ({
    label: opt.label,
    value: opt.value,
  }));
}

/**
 * Takes raw user response and converts it to be options for use but UserSelect, etc.
 *
 * @param data
 * @param suppressEmailAppend
 */
export function formatUsers(data: ListUser[], suppressEmailAppend = false): UserOption[] {
  const values: UserOption[] = [];
  if (data?.length) {
    data.forEach((user) => {
      values.push({
        value: user._id,
        label: user.email && !suppressEmailAppend ? `${user.name} (${user.email})` : user.name,
        name: user.name,
        email: user.email,
        image: user.imageFileId ? `/api/files/${user.imageFileId}?thumbnail=true` : "",
        identityVerified: user.identityVerified,
      });
    });
  }
  return uniqBy((u) => u.value, values);
}

export enum SpecialValuesKey {
  BUSINESS_OWNER = "Business Owner",
  OWNER_AND_APPROVAL_GROUPS = "Business Owner and Approval Groups",
}

async function getUsersCached(
  params: GetUserOptions,
  excludeIds: string[],
  nonNetworkEntityId?: string,
): Promise<ListUser[]> {
  let users: ListUser[];
  const entityForStorage = nonNetworkEntityId ? nonNetworkEntityId : getUserActiveEntityId();

  const cacheKey: ContactOptionsByEntityStorageKey = `${StorageKey.CONTACT_OPTIONS}_${entityForStorage}`;
  if (params.searchText) {
    users = await getUsers(params);
  } else {
    users = getFromStorage(cacheKey);
    if (!users) {
      users = await getUsers(params);
      saveInStorage<ContactOptionsByEntityStorageKey>(cacheKey, users);
    } else if (!isEmpty(excludeIds)) {
      const usersToKeep = users.filter((user) => !excludeIds.includes(user?._id));
      const unusedUsers = await getUsers({
        ...params,
        excludeIds: [...excludeIds, ...usersToKeep.map((user) => user?._id)],
      });
      users = [...usersToKeep, ...unusedUsers];
    }
  }
  return users;
}

/**
 * Gets the user options to be user with UserSelect, etc
 *
 * @param questionData
 * @param suppressEmailAppend
 * @param connectionOrKitContext
 * @param answers
 * @param isContact
 * @param searchText
 * @param nonNetworkEntityId
 * @param defaultUserIds
 */
export async function getUserInputOptions(options: UserInputOptions): Promise<UserOption[]> {
  const {
    questionData,
    suppressEmailAppend,
    connectionOrKitContext,
    answers,
    isContact,
    searchText,
    nonNetworkEntityId,
    defaultUserIds,
  } = options;
  const userType = questionData.list?.key;
  let userOptions;
  let users;
  const curAnswer: ContactAnswer | ContactAnswer[] | undefined = answers?.[questionData.key];
  const excludeIds: string[] = coerceToArray(curAnswer).map((value) => value.userId);

  const defaultUserParams: Partial<GetUserOptions> = {
    associationStatuses: [EntityAccessStatus.ACCEPTED, EntityAccessStatus.INVITED],
    applySegmentation: questionData.applySegmentationToOptions,
    answers: questionData.applySegmentationToOptions ? answers : undefined,
    ...((questionData.applySegmentationToOptions && connectionOrKitContext) || {}),
    limit: SELECT_DEFAULT_NUM_USERS,
    searchText,
    sort: {name: 1},
    excludeIds,
    fieldsForUserOptions: true,
  };

  if (isContact) {
    users = await getUsersCached(
      {
        searchText,
        limit: NUM_OF_CONTACTS_TO_SHOW,
        sort: {name: 1},
        excludeIds,
        nonNetworkEntityId,
      },
      excludeIds,
      nonNetworkEntityId,
    );
    userOptions = formatUsers(users, !!suppressEmailAppend);
  } else if (userType) {
    const params: Partial<GetUserOptions> = defaultUserParams;

    switch (userType) {
      case RoleTypeName.TOPIC_OWNER: {
        const parentTopic = findTopic(questionData);
        if (parentTopic && parentTopic.owningRole) {
          params.withRoleIds = [parentTopic.owningRole];
        }
        switch (questionData.actorRole) {
          case ConnectionRole.BUYER:
          case ConnectionRole.SELLER:
            params.withConnectionRole = questionData.actorRole;
            break;

          case ConnectionRole.BOTH:
          default:
            break;
        }
        break;
      }
      case SpecialValuesKey.BUSINESS_OWNER:
      case SpecialValuesKey.OWNER_AND_APPROVAL_GROUPS:
        // Any user
        break;
      default:
        params.withRoleIds = [userType];
        break;
    }

    users = await getUsers(params);
    if (defaultUserIds?.length) {
      params.userIds = defaultUserIds;
      params.includeSupportUsers = true;
      const defaultUsers = await getUsers(params);
      if (defaultUsers?.length) {
        users = [...defaultUsers, ...users];
      }
    }
    userOptions = formatUsers(users, !!suppressEmailAppend);
    userOptions = groupUserInputOptionsForOwnerApprovalGroups(questionData, userOptions);
    userOptions = [...(questionData.options || []), ...userOptions];
  } else if (questionData.options?.length) {
    userOptions = [...questionData.options];
  } else {
    users = await getUsers(defaultUserParams);
    if (defaultUserIds?.length) {
      defaultUserParams.userIds = defaultUserIds;
      defaultUserParams.includeSupportUsers = true;
      const defaultUsers = await getUsers(defaultUserParams);
      if (defaultUsers?.length) {
        users = [...defaultUsers, ...users];
      }
    }
    userOptions = formatUsers(users, !!suppressEmailAppend);
  }

  return userOptions;
}

export async function getUserInputOptionsByUserIds(
  questionData: JSONQuestion,
  userIds?: string[],
): Promise<UserOption[]> {
  if (questionData.options?.length) {
    return questionData.options;
  } else {
    const users = await getUsers({userIds, includeSupportUsers: true});
    return formatUsers(users);
  }
}

// BROKE THIS OUT FROM THE GET USER INPUT OPTIONS, I FEEL IT SHOULD BE SEPARATE - clark

export function groupUserInputOptionsForOwnerApprovalGroups(
  questionData: JSONQuestion,
  userOptions: UserOption[],
): UserOptionsGroup[] | UserOption[] {
  const approvalRoles = pathOr(null, ["config", "approvalRoles"], questionData);
  if (
    approvalRoles &&
    questionData.list?.key === SpecialValuesKey.OWNER_AND_APPROVAL_GROUPS &&
    questionData.groupValues
  ) {
    const roles = pathOr(null, ["config", "roles"], questionData);
    const origOptions = clone(userOptions);
    return [
      {
        group: translate("approvals.groups"),
        groupOptions: roles
          .filter((r) => approvalRoles.indexOf(r._id) > -1)
          .map((r) => {
            return {
              name: userLocalizeLabel(r.name),
              label: userLocalizeLabel(r.name),
              value: r._id,
              hideAvatar: true,
            };
          }),
      },
      {
        group: translate("approvals.approver"),
        groupOptions: origOptions,
      },
    ];
  }
  return userOptions;
}

export function getOptionLabel(options: QuestionOption[], optionValue: string): string {
  const found = baseFind((o) => o.value === optionValue, options);
  return found ? found.label : "";
}
