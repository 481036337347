/**
 * This file contains any exported composable code that is specific to working with a question tree data type.
 *
 * If it's more generic code that works with question data (and will be used on forms that aren't generated by a
 * question tree), then it does not belong here.
 */

import {componentGlobalOn} from "@/composables/globalEventBus";
import {answerSet} from "@/composables/questions/answers";
import {splitChildren} from "@/composables/questions/internalGroup";
import globalLogger from "@/logging";
import type {AnswerKeys} from "pg-isomorphic/api/answers";
import type {AdvancedSearchField} from "pg-isomorphic/api/search";
import {ElementType, GroupSubType, GroupType, Internal, QuestionType, Tax, ValidationStatus} from "pg-isomorphic/enums";
import {SpecialTabs} from "pg-isomorphic/enums/answers";
import {
  getAnswersFromPath,
  getOptionsFromListByAnswerPath,
  listItemsFind,
  listItemsFlatMapTo,
} from "pg-isomorphic/lists";
import {
  find,
  findGroup,
  findGroupInstance,
  findNearestNeighbor,
  findParent,
  findParentOfType,
  isContainer,
  isMultipleGroup,
  processTreeTopDown,
} from "pg-isomorphic/profile";
import {ExpressionEvaluator} from "pg-isomorphic/rules/expression";
import type {ExpressionResult, SingleExpressionResult} from "pg-isomorphic/rules/expression";
import type {JSONObject, JSONQuestion} from "pg-isomorphic/utils";
import {isEmptyOrUndefined} from "pg-isomorphic/utils";
import {stickyScroll, waitForSelector} from "pg-isomorphic/utils";
import {any, find as ramdaFind, findIndex, is, isNil, path, pathOr} from "ramda";
import type {ComputedRef, Ref} from "vue";
import {computed, onMounted, ref, watch} from "vue";
import VueScrollTo from "vue-scrollto";
import type {
  ChangeInstanceStateHandler,
  ChangeTab,
  ChangeTopicStateHandler,
  GetOptionsTreeData,
  GoToElement,
  NavigateToElementReturnData,
} from "@/composables/questions/types";
import type {TaskCount} from "pg-isomorphic/api/tasks";
import {injectStrict} from "@/composables/provideInject";
import {IsAdvancedSearch, GetOptionsTreeDataKey} from "@/composables/questions/injections";
import {getOptionsAsync} from "@/composables/questions/getOptions";
import type {NoteCount} from "pg-isomorphic/api/note";
import type {ReminderCount} from "pg-isomorphic/api/reminder";
import type {ApprovalCount} from "pg-isomorphic/api/approval";
import {QuestionBadge} from "pg-isomorphic/enums/question";
import type {QuestionOption} from "pg-isomorphic/options";
import {sortOptions} from "pg-isomorphic/options";
import {
  filterOptions,
  getOptionsData,
  GetOptionsMethod,
  getOptionsProcessingMethod,
  getOptionsWithoutServerCall,
} from "pg-isomorphic/options";
import {findReferenceTables} from "@/composables/questions/questionData";
import {addCountrySelectOptions} from "@/composables/questions/inputs";
import type {DecodeAnswerLabelLookup, Question} from "pg-isomorphic/props";
import {getTranslatedOptionsByKey} from "@/composables/lists";
import type {TranslatedListItem} from "pg-isomorphic/api/lists";
import keyBy from "lodash/keyBy";

const logger = globalLogger.getLogger("composable.questionTree");

const isObject = is(Object);
export const COMPUTE_LOG_STORAGE_KEY = "_pg_compute_log";
export const COMPUTE_PROP_STORAGE_KEY = "_pg_prop_compute_log";

/**
 * Takes a given JSON question and compiles a list of answer keys for both it's own question and all child questions
 * @param questionData
 */
export function getAnswerKeysFromElement(questionData: JSONQuestion): AnswerKeys {
  const kidsKeys: AnswerKeys = {};
  if (!questionData) {
    return kidsKeys;
  }
  const recursiveElementKeyAssembler = (q: JSONQuestion, groupKey?: string) => {
    if (isMultipleGroup(q)) {
      groupKey = q.key;
      kidsKeys[groupKey] = {};
    } else if (!isContainer(q)) {
      if (groupKey) {
        // @ts-ignore
        kidsKeys[groupKey][q.key] = true;
      } else {
        kidsKeys[q.key] = true;
      }
    }
    if (q.children) {
      q.children.forEach((child) => recursiveElementKeyAssembler(child, groupKey));
    }
  };
  recursiveElementKeyAssembler(questionData);
  return kidsKeys;
}

/**
 * If it has a instance parent it will find it and return the instance id, other a blank string
 * @param questionData
 */
export function getParentInstanceId(questionData: JSONQuestion): string {
  return findParent((q: JSONQuestion) => q.type === GroupType.GROUP_INSTANCE)(questionData)?.instance || "";
}

/**
 * Return the parent topic / subtopic question data object
 * @param questionData
 */
export function getParentTopic(questionData: JSONQuestion): JSONQuestion {
  return (
    questionData.parent &&
    findParent((q) => q.type === GroupType.TOPIC || q.type === GroupType.SUBTOPIC, questionData.parent)
  );
}

/**
 * If it has a parent group type with multiple true, returns the question data object
 * @param questionData
 */
export function getParentGroup(questionData: JSONQuestion): JSONQuestion {
  return findParent(isMultipleGroup, questionData);
}

export function hasParentQuestion(questionData: JSONQuestion): boolean {
  return isObject(path(["parent"], questionData));
}

export function showInternalUseIcon(questionData: JSONQuestion): boolean {
  if (questionData.internalUse) {
    if (questionData.type === GroupType.TOPIC) {
      return true;
    } else {
      // Show icon if parent does not have the icon.
      return !questionData.parent?.internalUse;
    }
  }
  if (questionData.counterpartyCanEditAnswer) {
    return questionData.type !== GroupType.TOPIC && questionData.type !== GroupType.SUBTOPIC;
  }
  return false;
}

/**
 * Returns the order of the topics. It assumes you are giving it the root of question tree.
 * @param questionTree
 */
export function getTopicOrderObject(questionTree: JSONObject): {[key: string]: number} {
  const topicOrderObj = {};
  if (questionTree.type !== GroupType.ROOT) {
    return topicOrderObj;
  }

  let orderNum = 0;
  const tabLevel = pathOr([], ["children"], questionTree) as JSONObject[];
  for (const tab of tabLevel) {
    for (const topic of pathOr([], ["children"], tab) as JSONObject[]) {
      topicOrderObj[String(topic._id)] = orderNum++;
      for (const possibleSubTopics of pathOr([], ["children"], topic) as JSONObject[]) {
        if (possibleSubTopics.type === GroupType.SUBTOPIC) {
          topicOrderObj[String(possibleSubTopics._id)] = orderNum++;
        }
      }
    }
  }

  return topicOrderObj;
}

/**
 * Returns array of siblings to current question that have the secure flag
 *
 * @param questionData
 */
export function getSecureSiblings(questionData: JSONQuestion): JSONQuestion[] {
  let parent = getParentTopic(questionData);
  if (questionData.parent?.type === ElementType.GROUP_INSTANCE) {
    // handle groups
    parent = questionData.parent;
  }
  const secureChildren: JSONQuestion[] = [];
  processTreeTopDown((c: JSONQuestion) => {
    if (c.secured && c.type !== QuestionType.OBSCURED) {
      secureChildren.push(c);
    }
  })(parent);
  return secureChildren;
}

/**
 * Grabs and "external" validation status from an answer key (where it's set)
 *
 * @param inputValue
 * @param questionData
 * @param profileAnswers
 */
export function getExternalValidationStatus(
  inputValue: any,
  questionData: JSONQuestion,
  profileAnswers: {[key: string]: any},
) {
  if (
    !questionData.externalValidation ||
    inputValue === "" ||
    isNil(inputValue) ||
    questionData.hideExternalValidation
  ) {
    return "";
  }
  const group = findGroup(questionData);
  const instance = findGroupInstance(questionData);
  const keyParts =
    group && instance
      ? `${questionData.key}${Internal.VALIDATION_STATUS}`.split(".")
      : [`${questionData.key}${Internal.VALIDATION_STATUS}`];
  return String(pathOr("", keyParts, profileAnswers));
}

export function getExternalValidationStatusComputed(
  inputValue: Ref<any>,
  questionData: Ref<JSONQuestion>,
  profileAnswers: Ref<{[key: string]: any}>,
) {
  return computed(() => {
    return getExternalValidationStatus(inputValue.value, questionData.value, profileAnswers.value);
  });
}

export function getUnverifiedStatus(inputValue: any, questionData: JSONQuestion, profileAnswers: {[key: string]: any}) {
  if (isEmptyOrUndefined(inputValue)) {
    return false;
  }
  const group = findGroup(questionData);
  const instance = findGroupInstance(questionData);
  const keyParts =
    group && instance
      ? `${questionData.key}${Internal.UNVERIFIED}`.split(".")
      : [`${questionData.key}${Internal.UNVERIFIED}`];
  return Boolean(pathOr(false, keyParts, profileAnswers));
}

export function getUnverifiedStatusComputed(
  inputValue: Ref<any>,
  questionData: Ref<JSONQuestion>,
  profileAnswers: Ref<{[key: string]: any}>,
): ComputedRef<boolean> {
  return computed(() => {
    return getUnverifiedStatus(inputValue.value, questionData.value, profileAnswers.value);
  });
}

export function setExternalValidationStatusToPending(
  inputValue: any,
  questionData: JSONQuestion,
  profileAnswers: {[key: string]: any},
): [boolean, string, string] {
  if (!questionData.externalValidation || inputValue === "" || isNil(inputValue)) {
    return [null, null, null];
  }
  const group = findGroup(questionData);
  const instance = findGroupInstance(questionData);
  const keyParts =
    group && instance
      ? `${questionData.key}${Internal.VALIDATION_STATUS}`.split(".")
      : [`${questionData.key}${Internal.VALIDATION_STATUS}`];
  const currentValue = String(pathOr("", keyParts, profileAnswers));
  if (currentValue !== ValidationStatus.PENDING) {
    answerSet(keyParts.join("."), ValidationStatus.PENDING, profileAnswers);
    return [true, keyParts.join("."), ValidationStatus.PENDING];
  }
  return [false, null, null];
}

export function getQuestionBadgeComputed(questionData: Ref<JSONQuestion>, profileAnswers: Ref<{[key: string]: any}>) {
  const badges = [];
  return computed(() => {
    if (questionData.value[QuestionBadge.DISREGARDED_ENTITY]) {
      if (profileAnswers.value[Tax.DISREGARED_TAX_ENTITY]) {
        badges.push(QuestionBadge.DISREGARDED_ENTITY);
      }
    }
    return badges;
  });
}

export function childrenAreReadonly(question: JSONQuestion) {
  if (!question.children?.length) {
    return false;
  }
  for (const child of question.children) {
    if (!child.visible) {
      continue;
    }
    if (child.type === QuestionType.COLUMN_ROW && question.children?.length) {
      const grandchildrenAreReadonly = childrenAreReadonly(child);
      if (!grandchildrenAreReadonly) {
        return false;
      }
    } else if (!child.readOnly) {
      return false;
    }
  }
  return true;
}

export function getAnyInputChildrenVisible(question: JSONQuestion) {
  if (!question.children || question.children.length === 0) {
    return false;
  }

  return any((e: JSONQuestion) => {
    if (e.type === QuestionType.DIVIDER || e.type === QuestionType.STOP || e.type === QuestionType.VISUAL_WORKFLOW) {
      return getAnyInputChildrenVisible(e);
    }
    return e.visible === true;
  }, question.children);
}

export function getHasOwnQuestions(elementData: JSONQuestion): boolean {
  return Boolean(
    elementData.children &&
      ramdaFind(
        (c: JSONQuestion) =>
          c.type !== GroupType.SUBTOPIC &&
          c.subType !== GroupSubType.ITEM &&
          c.visible &&
          c.editable &&
          !c.hideSourceTable,
        elementData.children,
      ),
  );
}

export function findQuestion(elementId: string, questions: JSONQuestion): JSONQuestion {
  return find((e) => e._id === elementId, questions) || null;
}

export function findTopicFromTopicKey(topicKey: string, questions: JSONQuestion) {
  return find((e) => e.type === GroupType.TOPIC && e.key === topicKey, questions);
}

export function updateTasksCount(elementId: string, instanceId: string, change: number, counts: TaskCount[]) {
  // change needed because there are group tasks with missing instanceId
  const idx = findIndex(
    (e) => e.elementId === elementId && ((!instanceId && !e.instanceId) || (instanceId && e.instanceId === instanceId)),
    counts,
  );
  if (idx < 0 && change) {
    counts.push({
      elementId,
      instanceId,
      count: change,
    });
  } else {
    counts[idx].count += change;
  }
}

export function updateNotesCount(elementId: string, instanceId: string, change: number, counts: NoteCount[]) {
  const idx = findIndex((e) => e.elementId === elementId && (!e.instanceId || e.instanceId === instanceId), counts);
  if (idx < 0 && change) {
    counts.push({
      elementId,
      instanceId,
      count: change,
    });
  } else {
    counts[idx].count += change;
  }
}

export function updateReminderCount(
  elementId: string,
  instanceId: string,
  update: number,
  counts: ReminderCount[],
  upcoming?: number,
  incomplete?: number,
  completed?: number,
) {
  const idx = findIndex(
    (r: ReminderCount) => r.elementId === elementId && (!r.instanceId || r.instanceId === instanceId),
    counts,
  );
  if (idx < 0 && update) {
    counts.push({
      count: update,
      elementId,
      instanceId,
      overdueCount: 0,
    });
  } else {
    if (!isNil(update)) {
      counts[idx].count += update;
    } else if (!isNil(incomplete) || !isNil(upcoming) || !isNil(completed)) {
      counts[idx].count = incomplete + upcoming + completed;
      counts[idx].overdueCount = incomplete;
    }
  }
}

export function updateApprovalCount(elementId: string, instanceId: string, update: number, counts: ApprovalCount[]) {
  const idx = findIndex(
    (r: ApprovalCount) => r.elementId === elementId && (!r.instanceId || r.instanceId === instanceId),
    counts,
  );
  if (idx < 0 && update) {
    counts.push({
      count: update,
      elementId,
      instanceId,
      incompleteCount: 0,
      mostRecentIsApproved: false,
    });
  } else {
    if (!isNil(update)) {
      counts[idx].count += update;
    }
  }
}

export function handleTableAndReferenceTableInstanceStates(
  groupElement: JSONQuestion,
  instanceId: string,
  initializedQuestionTree: JSONQuestion,
  answers: JSONObject,
  changeTopicState: ChangeTopicStateHandler,
  changeInstanceState: ChangeInstanceStateHandler,
) {
  if (groupElement.subType === GroupSubType.ITEM) {
    changeTopicState(`${groupElement.key}_${instanceId}`, true);
  } else {
    changeInstanceState(groupElement._id, instanceId, true);
  }

  if (groupElement.hideSourceTable) {
    // This means that the parent table is actually referenced, so we must find the reference table.
    const referencesTablesToParentTables = findReferenceTables(
      instanceId,
      groupElement.key,
      initializedQuestionTree,
      answers,
    );
    if (referencesTablesToParentTables.length) {
      let firstReferenceTable = referencesTablesToParentTables[0]; // Possible to have more than one, so we'll just do the first
      let parentGroup = findParent((p) => p.type === GroupType.GROUP, firstReferenceTable);
      let parentGroupInstance = findParent((p) => p.type === GroupType.GROUP_INSTANCE, firstReferenceTable);

      if (parentGroup) {
        handleTableAndReferenceTableInstanceStates(
          parentGroup,
          parentGroupInstance.instance,
          initializedQuestionTree,
          answers,
          changeTopicState,
          changeInstanceState,
        );
      }
    }
  }
}

export async function navigateToElementQuestionTree(
  waitForElement: any,
  goToData: GoToElement,
  profileQuestions: JSONQuestion,
  answers: JSONObject,
  suppressFlashEffect,
  changeTab: ChangeTab,
  changeTopicState: ChangeTopicStateHandler,
  changeInstanceState: ChangeInstanceStateHandler,
): Promise<NavigateToElementReturnData> {
  logger.trace(() => `navigateToElementQuestionTree`, goToData);
  let element = goToData.elementId ? find((e) => e._id === goToData.elementId, profileQuestions) : null;
  if (!element) {
    // Probably hidden?
    return;
  }

  // If there is only one sub topic then we want to open that. The easiest way to do this is set that as the element
  if (element.type === GroupType.TOPIC && element.children.length === 1) {
    element = element.children[0];
  }

  // If a hidden referenced table, find the first reference to it and navigate to that instead since the source is hidden
  if (pathOr(false, ["hideSourceTable"], element)) {
    element = findNearestNeighbor(
      (q) => q.type === QuestionType.REFERENCE_TABLE && q.optionsAnswerKey === element.key && q.visible,
      profileQuestions,
    );
    const instanceElement = findParent((p) => p.type === GroupType.GROUP_INSTANCE, element);
    goToData.instanceId = instanceElement.instance;
  }

  const parentTopic = findParentOfType(GroupType.TOPIC, element);
  const parentSubtopic = findParentOfType(GroupType.SUBTOPIC, element);
  const parentTab = findParentOfType(GroupType.TAB, element);
  let parentGroupItem = goToData.instanceId ? findParent((p) => p.subType === GroupSubType.ITEM, element) : null;
  let parentGroupTable = goToData.instanceId ? findParent((p) => p.subType === GroupSubType.TABLE, element) : null;
  let parentTicketTable = goToData.instanceId ? findParent((p) => p.subType === GroupSubType.TICKET, element) : null;
  let parentReviewTable = goToData.instanceId ? findParent((p) => p.subType === GroupSubType.REVIEW, element) : null;

  // Open Topics, Table Instance
  changeTab(parentTab.key);
  if (parentTopic) {
    changeTopicState(parentTopic.key, true);
  }
  if (parentSubtopic) {
    changeTopicState(parentSubtopic.key, true);
  }
  if (parentGroupItem) {
    changeTopicState(`${parentGroupItem.key}_${goToData.instanceId}`, true);
  }
  if (parentGroupTable) {
    // changeInstanceState(parentGroupTable._id, goToData.instanceId, true);
    // This means that the parent table is actually referenced, so we must find the reference table.
    handleTableAndReferenceTableInstanceStates(
      parentGroupTable,
      goToData.instanceId,
      profileQuestions,
      answers,
      changeTopicState,
      changeInstanceState,
    );
  }

  if (parentTicketTable) {
    changeInstanceState(parentTicketTable._id, goToData.instanceId, true);
  }

  if (element.subType === GroupSubType.TICKET || parentTicketTable) {
    changeInstanceState(element._id, goToData.instanceId, true);
  }

  if (parentReviewTable) {
    changeInstanceState(parentReviewTable._id, goToData.instanceId, true);
  }

  let queryElementId = element._id;
  if (goToData.expandReview) {
    // Find review in element id
    const review = find((e) => e.subType === GroupSubType.REVIEW && e.visible, element);
    const reviewParentSubtopic = findParentOfType(GroupType.SUBTOPIC, review);
    queryElementId = review._id;

    // Expand the most recent review if present.
    changeTopicState(reviewParentSubtopic.key, true);
    changeInstanceState(review._id, goToData.instanceId, true);
  }

  // Scroll To Element
  const selector = `#element_${queryElementId}`;
  const error = await waitForSelector({
    target: waitForElement,
    selector,
    timeout: 60000,
  });

  if (!goToData.disableScrollTo) {
    setTimeout(() => {
      requestAnimationFrame(() => {
        if (!error) {
          VueScrollTo.scrollTo(selector, 300, {
            offset: -157,
            onDone: (elem) => {
              // setTimeout(() => this.$router.replace({ replace: false }), 300);
              if (!suppressFlashEffect) {
                elem.classList.add("flash-highlight");
              }
              stickyScroll({
                elem,
                offset: -157,
              });
            },
            container: goToData.containerId,
          });
        } else {
          // show popup saying the item isn't currently in their profile
          // getToast().warning("This question is not available on graphiteConnect.");
          console.error(
            `The question ${element?.key} (${element?._id}) using selector (${selector}) is not available on graphiteConnect.`,
          );
        }
      });
    }, 1000);
  }

  return {
    element,
    parentTopic,
    parentSubtopic,
    parentTab,
    parentGroupItem,
    parentGroupTable,
    parentReviewTable,
    instanceId: goToData.instanceId,
    error,
  };
}

/**
 * Get options for components. This does it more intelligently to optimize for reactive changes. In the case of use
 * optionsAnswerKey we need to "listen" to the question tree. In the other cases we only care about changes to element
 * data.
 *
 * @param elementData
 * @param userLocale
 */
export function getOptionsForQuestionTreeElements(elementData: Ref<JSONQuestion>, userLocale: string) {
  const providedTreeDataForOptions: GetOptionsTreeData = injectStrict(GetOptionsTreeDataKey, null);
  const isAdvancedSearch: boolean = injectStrict(IsAdvancedSearch, false);
  let {answers, questions, answerKey} = getOptionsData(elementData.value.optionsAnswerKey, {
    questions: providedTreeDataForOptions?.questions?.value,
    answers: providedTreeDataForOptions?.answers?.value,
    counter: {
      questions: providedTreeDataForOptions?.counter?.questions?.value,
      answers: providedTreeDataForOptions?.counter?.answers?.value,
    },
  });

  const parentAnswers: ComputedRef<Array<string | string[] | undefined>> = computed(() => {
    if (!providedTreeDataForOptions?.answers?.value || !elementData.value.list?.answerPath?.length) {
      return null;
    }

    return getAnswersFromPath(providedTreeDataForOptions?.answers?.value, elementData.value.list?.answerPath);
  });

  const optionsProcessedMethod =
    // if we have a list.key, we'll always call `getOptionsProcessingMethod` (which will return `GET_OPTIONS_FROM_SERVER`)
    elementData.value.list?.key ||
    providedTreeDataForOptions ||
    // `forSearch` means this is actually a faux question built from `AdvancedSearchField`
    (elementData.value as unknown as AdvancedSearchField).forSearch
      ? getOptionsProcessingMethod(elementData.value, answers)
      : {getOptionsMethod: GetOptionsMethod.USE_OPTIONS_PROPERTY, filterOptions: false};
  let optionsComputed: ComputedRef<QuestionOption[]> = null;
  const fetchingOptions = ref(false);

  const refreshCount: Ref<number> = ref(1);
  if (elementData.value.optionsFilter) {
    const filterOptionAnswerKeys: ComputedRef<string[]> = computed(() => {
      if (elementData.value.optionsFilter) {
        return new ExpressionEvaluator(elementData.value.optionsFilter).answerKeys;
      }
      return [];
    });

    // feels less cryptic than creating a watch "key"
    componentGlobalOn("answer:answerChanged", async ({key}) => {
      if (filterOptionAnswerKeys.value.includes(key)) {
        ({answers, questions, answerKey} = getOptionsData(elementData.value.optionsAnswerKey, {
          questions: providedTreeDataForOptions?.questions?.value,
          answers: providedTreeDataForOptions?.answers?.value,
          counter: {
            questions: providedTreeDataForOptions?.counter?.questions?.value,
            answers: providedTreeDataForOptions?.counter?.answers?.value,
          },
        }));
        refreshCount.value += 1;
      }
    });
  }

  if (
    optionsProcessedMethod.getOptionsMethod === GetOptionsMethod.USE_OPTIONS_PROPERTY ||
    optionsProcessedMethod.getOptionsMethod === GetOptionsMethod.GET_OPTIONS_FROM_GROUP_INSTANCES ||
    optionsProcessedMethod.getOptionsMethod === GetOptionsMethod.GET_OPTIONS_FROM_ANSWERS
  ) {
    optionsComputed = computed(() => {
      if (refreshCount.value) {
        return getOptionsWithoutServerCall(elementData.value, optionsProcessedMethod, answers, questions, answerKey);
      }
    });
    watch(parentAnswers, () => {
      getOptionsWithoutServerCall(elementData.value, optionsProcessedMethod, answers, questions, answerKey);
    });
  } else {
    let options: Ref<QuestionOption[]> = ref([]);
    fetchingOptions.value = true;
    onMounted(async () => {
      options.value = await getOptionsAsync(
        {...elementData.value, optionsFilter: undefined},
        {
          questions: providedTreeDataForOptions?.questions?.value,
          answers: providedTreeDataForOptions?.answers?.value,
          counter: {
            questions: providedTreeDataForOptions?.counter?.questions?.value,
            answers: providedTreeDataForOptions?.counter?.answers?.value,
          },
        },
        userLocale,
        optionsProcessedMethod,
      );

      fetchingOptions.value = false;
      options.value = sortOptions(options.value, elementData.value.list?.key);
    });

    if (optionsProcessedMethod?.getOptionsMethod === GetOptionsMethod.GET_OPTIONS_FROM_SERVER) {
      watch([elementData, parentAnswers], async () => {
        options.value = await getOptionsAsync(
          {...elementData.value, optionsFilter: undefined},
          {
            questions: providedTreeDataForOptions?.questions?.value,
            answers: providedTreeDataForOptions?.answers?.value,
            counter: {
              questions: providedTreeDataForOptions?.counter?.questions?.value,
              answers: providedTreeDataForOptions?.counter?.answers?.value,
            },
          },
          // Swap `null` with `userLocale` once OPG-10081 is fixed. Also see OPG-9719.
          // Until then, this `null` forces the e.g. English countries, needed for the advanced search since we index on English labels
          isAdvancedSearch ? null : userLocale,
          // userLocale,
          optionsProcessedMethod,
        );
        options.value = sortOptions(options.value, elementData.value.list?.key);
      });
    }
    optionsComputed = computed(() => {
      if (refreshCount.value) {
        const filteredOptions = filterOptions(options.value, elementData.value.optionsFilter, answers);
        return addCountrySelectOptions(filteredOptions, elementData.value);
      }
      return options.value;
    });
  }
  return {optionsComputed, providedTreeDataForOptions, fetchingOptions};
}

/**
 * Takes a question tree and checks for a single visible topic. I could have just looked for topics and check visibility
 * but did the much more specific assumptive approach for performance reasons.
 *
 * @param questionTree
 * @param displayTabKeys
 */
export function checkForSingleVisibleTopic(questionTree: JSONQuestion, displayTabKeys: string[] = null): string {
  const visibleTabs: JSONQuestion[] = [];
  for (const child of questionTree.children) {
    if (
      child.type === GroupType.TAB &&
      child.visible &&
      (displayTabKeys ? displayTabKeys.indexOf(child.key) > -1 : true)
    ) {
      visibleTabs.push(child);
    }
  }

  if (visibleTabs.length > 1) {
    return null;
  }

  const visibleTopics: JSONQuestion[] = [];
  for (const visibleTab of visibleTabs) {
    for (const tabChild of visibleTab.children) {
      if (tabChild.type === GroupType.TOPIC && tabChild.visible) {
        visibleTopics.push(tabChild);
      }
    }
  }

  if (visibleTopics.length === 1) {
    return visibleTopics[0].key;
  }

  return null;
}

export function organizeQuestionDataTabs(
  questionData: JSONQuestion,
  displayTabKeys: string[],
  skipVisibleFilter?: boolean,
) {
  if (!questionData) {
    return [];
  }

  const children = questionData.children;

  if (children?.[0]?.type === GroupType.TAB) {
    const filterMethod = displayTabKeys
      ? (t: JSONQuestion) => (t.visible || skipVisibleFilter) && displayTabKeys.indexOf(t.key) !== -1
      : (t: JSONQuestion) => t.visible || skipVisibleFilter;
    const results = (questionData?.children || []).filter(filterMethod);

    if (displayTabKeys?.[0] === SpecialTabs.OVERVIEW) {
      const updated = [...results];
      if (updated[0]) {
        updated[0] = {
          ...updated[0],
          children: splitChildren(updated[0], updated[0].children),
        };
      }
      return updated;
    }

    return results;
  }

  return [];
}

export const decodeAnswerLabelLookup: (
  questionData: JSONQuestion,
  answers: Ref<any>,
  userLocale: string,
) => DecodeAnswerLabelLookup = (
  // Heh, we're not even using this now. But I'm keeping this structure for now because in principle if we ever come up against a GIG case
  // we might need to change the `findNearestNeighbor` call below and actually find the "parent" group instance
  _questionData: JSONQuestion,
  answers: Ref<any>,
  userLocale: string,
) => {
  return async (
    answer: ExpressionResult,
    questionId: ExpressionResult,
    lookupColumn: ExpressionResult,
    expressionQuestion: Question,
  ) => {
    const question: JSONQuestion = findNearestNeighbor(
      (q) => q.questionId === questionId || q.originalQuestionId === questionId,
      expressionQuestion,
    ) as unknown as JSONQuestion;

    if (!question?.list?.key) {
      return answer;
    }

    const listItems = await getTranslatedOptionsByKey(question.list.key, question.owner, userLocale);

    if (!listItems) {
      return answer;
    }

    const isMultiAnswer = Array.isArray(answer);

    // first, try to find the subset of list items with the correct ancestry
    const listSlice = getOptionsFromListByAnswerPath(listItems, answers.value, question?.list?.answerPath);

    let matchingListItems: TranslatedListItem[] = [];

    if (isMultiAnswer) {
      matchingListItems = listSlice.filter((item) => answer.includes(item.value));

      if (!matchingListItems.length) {
        // fall back to just finding ANY list items with this value
        matchingListItems = listItemsFlatMapTo(listItems, (item) => item).filter((item) => answer.includes(item.value));
      }
    } else {
      let matchingListItem: TranslatedListItem | undefined = listSlice.find((item) => item.value === answer);

      if (!matchingListItem) {
        // fall back to just finding ANY list item with this value
        matchingListItem = listItemsFind(listItems, (item) => item.value === answer);
      }

      if (matchingListItem) {
        matchingListItems = [matchingListItem];
      }
    }

    if (!matchingListItems.length) {
      return answer;
    }

    const answerArray: SingleExpressionResult[] = Array.isArray(answer) ? answer : [answer];

    const matchingListItemByValue = keyBy(matchingListItems, (item) => item.value);

    const results: SingleExpressionResult[] = answerArray.map((ans) => {
      const item = matchingListItemByValue[ans];

      if (lookupColumn) {
        return item?.lookup?.[lookupColumn] || ans;
      }

      return item?.label || ans;
    });

    return isMultiAnswer ? results : results[0];
  };
};
